import React, { useEffect, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import Wrapper from "../../Components/HOC/Wrapper";
import RequestBuilderInput from "../../Components/Input/RequestBuilderInput";
import { Tooltip } from "@material-tailwind/react";
import { useWebsiteList, useGetWebsiteById } from "../../hook/useWebsite";
import { useLocation, useNavigate } from "react-router";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, {Toast} from "react-hot-toast"
const RequestBuilder = ({ urlInput }) => {
  const [initioalInput, setInitioalInput] = React.useState({
    url: "",
    javaScript: false,
    waitFor: "", //checkbox field
    blockResource: false, //checkbox field
    cookies: "",
    screenShot: false, //checkbox field
    width: "",
    height: "",
    bypassCloudflare: "",
    undetectedScraping: "",
    userAgent: "",
    customHeaderName1: "",
    customHeaderName2: "",
    customHeaderName3: "",
    customHeaderValue1: "",
    customHeaderValue2: "",
    customHeaderValue3: "",
    confirmPhrase: "",
  });

  const {
    data: websiteInfo,
    mutate: getWebsiteById,
    isLoading: webSiteInfoLoading,
  } = useGetWebsiteById();

  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");

  useEffect(() => {
    if (location.search) {
      let splitedLocation = location.search.split("&");
      let id = splitedLocation[0].replace("?id=", "");
      let pProxyWebsiteId = splitedLocation[1].replace("proxyWebsiteId=", "");
      setWebsite(pProxyWebsiteId);

      getWebsiteById(id);
    } else {
      navigate("/website");
    }
  }, [location]);

  useEffect(() => {
    if (websiteInfo) {
      setName(websiteInfo.item.websiteUrl);
    }
  }, [websiteInfo]);

  const changeHandler = (e) => {
    //  console.log(e.target.name , e.target.value);
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setInitioalInput({
      ...initioalInput,
      [e.target.name]: value,
    });
  };
  const initialValueTextArea = () => {
    let urlValue = "";
    let waitForValue = "";
    let javaScriptValue = "";
    let blockResourceValue = "";
    let cookiesValue = "";
    let screenShotValue = "";
    let widthValue = "";
    let heightValue = "";
    let userAgentValue = "";
    let bypassCloudflareValue = "";
    let undetectedScrapingValue = "";

    if (initioalInput.url.length > 0) {
      urlValue = `/` + initioalInput.url;
      //  console.log(urlValue)
    }
    if (initioalInput.javaScript) {
      javaScriptValue = "&javaScriptRendering=" + initioalInput.javaScript;
    }
    if (initioalInput.waitFor.length > 0) {
      waitForValue = "&waitFor=" + initioalInput.waitFor;
    }
    if (initioalInput.blockResource) {
      blockResourceValue = "&blockResource=" + initioalInput.blockResource;
    }
    if (initioalInput.cookies) {
      cookiesValue = "&cookies=" + initioalInput.cookies;
    }
    if (initioalInput.screenShot) {
      screenShotValue = "&screenShot=" + initioalInput.screenShot;
    }
    if (initioalInput.width) {
      widthValue = "&width=" + initioalInput.width;
    }
    if (initioalInput.height) {
      heightValue = "&height=" + initioalInput.height;
    }
    if (initioalInput.userAgent) {
      userAgentValue = "&userAgent=" + initioalInput.userAgent;
    }
    if (initioalInput.bypassCloudflare) {
      bypassCloudflareValue =
        "&bypassCloudflare=" + initioalInput.bypassCloudflare;
    }
    if (initioalInput.undetectedScraping) {
      undetectedScrapingValue =
        "&undetectedScraping=" + initioalInput.undetectedScraping;
    }

    return (
      urlValue +
      waitForValue +
      javaScriptValue +
      blockResourceValue +
      cookiesValue +
      screenShotValue +
      widthValue +
      heightValue +
      bypassCloudflareValue +
      undetectedScrapingValue +
      userAgentValue
    );
  };

  const customeValueTextArea = () => {
    let customHeaderName1 = "";
    let customHeaderName2 = "";
    let customHeaderName3 = "";
    let customHeaderValue1 = "";
    let customHeaderValue2 = "";
    let customHeaderValue3 = "";
    if (initioalInput.customHeaderName1) {
      customHeaderName1 = "&" + "ph-" + initioalInput.customHeaderName1;
    }
    if (initioalInput.customHeaderValue1) {
      customHeaderValue1 = "=" + '"' + initioalInput.customHeaderValue1 + '"';
    }
    if (initioalInput.customHeaderName2) {
      customHeaderName2 = "&" + "ph-" + initioalInput.customHeaderName2;
    }
    if (initioalInput.customHeaderValue2) {
      customHeaderValue2 = "=" + '"' + initioalInput.customHeaderValue2 + '"';
    }
    if (initioalInput.customHeaderName3) {
      customHeaderName3 = "&" + "ph-" + initioalInput.customHeaderName3;
    }
    if (initioalInput.customHeaderValue3) {
      customHeaderValue3 = "=" + '"' + initioalInput.customHeaderValue3 + '"';
    }

    return (
      customHeaderName1 +
      "" +
      customHeaderValue1 +
      "" +
      customHeaderName2 +
      "" +
      customHeaderValue2 +
      "" +
      customHeaderName3 +
      "" +
      customHeaderValue3
    );
  };

  const confirmPhrase = () => {
    let confirmPhraseVlue = "";

    if (initioalInput.confirmPhrase) {
      confirmPhraseVlue = "&confirmPhrase=" + initioalInput.confirmPhrase;
    }
    return confirmPhraseVlue;
  };
  let datakkey = localStorage.getItem("apiKey");
  let palanName = localStorage.getItem("planName");

  // const [formCopy, setFormCopy] = useState("");

  let formCopy = `https://api.pushane.ir/api/Scrapper/V1/?api_key=${datakkey}&websiteId=${website}&url=${name}${initialValueTextArea()}${customeValueTextArea()}${confirmPhrase()}`;

  const textArealChangeHandler = (event) => {
    if(
    navigator.clipboard.writeText(formCopy)){
      toast.success("Copied" , {position: "top-center"})
    }
      
    
    // console.log(event.target.parentElement.children[0].value);
    // setFormCopy(event.target.value);
  };

  return (
    <>
      <Wrapper>
        <div className="flex flex-row  w-full " style={{ direction: "ltr" }}>
          <div className="w-1/2 md:min-w-0 px-5 ">
            <p className="ml-5 font-semibold flex">
              <span>URL (requierd)</span>

              <Tooltip
                className="py-5 px-5" style={{direction:"ltr"}}
                content="Url you want to scrape. Must be correctly encoded"
              >
                <InfoCircle className="mx-2 mt-1 font-semibold" />
              </Tooltip>
            </p>

            {/* <div className="flex flex-row "> */}
            <div className="flex items-center justify-start">
              <lable className=" tracking-wide font-semibold px-2">
                {name}/
              </lable>

              <RequestBuilderInput
                    className="w-full "
                    type="text"
                    name="url"
                    placeholder="Continue writing your url "
                    onChange={changeHandler}
                    value={initioalInput.url}
                  />


           
            </div>

            <div className="mt-20">
              <p className="m-5 font-semibold flex">
                {" "}
                <span>Wait for (css selector)</span>
                <Tooltip
                
                  className="py-5 px-5 text-justify"
                  content="The CSS selector of the element our server will wait for before returning you the result. Useful for heavy websites. Only works with JavaScript rendering"
                >
                  <InfoCircle className="mx-4 mt-1 font-semibold" />
                </Tooltip>
              </p>
              <RequestBuilderInput
                className=" w-full "
                name="waitFor"
                type="text"
                onChange={changeHandler}
                value={initioalInput.waitFor}
                placeholder=""
              />
            </div>

            {/* /header/////////////////////////////// */}

            <div className="flex flex-col">
              <div className="flex flex-col w-full">
                <p className="m-5 font-semibold flex">
                  <span>Cookies</span>
                  <Tooltip
                    className="py-5 px-5"
                    content="Forward custom cookies to the website you want to scrape"
                  >
                    <InfoCircle className="mx-2 mt-1 font-semibold" />
                  </Tooltip>
                </p>

                <RequestBuilderInput
                  className=" "
                  type="text"
                  name="cookies"
                  placeholder="cookie_name_1=cookie_value1;"
                  onChange={changeHandler}
                  value={initioalInput.cookies}
                />
                <div className="flex flex-col w-full">
                  <p className="m-5 font-semibold flex  ">
                    <span>Confirm Phrase</span>
                    <Tooltip
                      className="py-5 px-5"
                      content="A phrase to confirm page is loaded truly or not"
                    >
                      <InfoCircle className="mx-2 mt-1 font-semibold" />
                    </Tooltip>
                  </p>

                  <RequestBuilderInput
                    className=" "
                    type="text"
                    name="confirmPhrase"
                    placeholder="Confirm Phrase"
                    onChange={changeHandler}
                    value={initioalInput.confirmPhrase}
                  />
                </div>
              </div>
            </div>
            <div className="w-fit">
              <p className="m-5 font-semibold flex">
                {" "}
                <span>Custom Header </span>{" "}
                <Tooltip
                  className="py-5 px-5"
                  content="Forward custom headers to the website you want to scrape"
                >
                  <InfoCircle className="mx-2 mt-1 font-semibold" />
                </Tooltip>
              </p>
              <div className="flex flex-row mt-5">
                <RequestBuilderInput
                  className=""
                  type="text"
                  name="customHeaderName1"
                  placeholder="Header 1 Name"
                  onChange={changeHandler}
                  value={initioalInput.customHeaderName1}
                />
                <span className="mt-2 px-1">=</span>
                <RequestBuilderInput
                  className=""
                  name="customHeaderValue1"
                  type="text"
                  placeholder="Header 1 Value"
                  onChange={changeHandler}
                  value={initioalInput.customHeaderValue1}
                />
              </div>

              <div className="flex flex-row mt-5">
                <RequestBuilderInput
                  className=""
                  type="text"
                  name="customHeaderName2"
                  placeholder="Header 2 Name"
                  onChange={changeHandler}
                  value={initioalInput.customHeaderName2}
                />
                <span className="mt-2 px-1 ">=</span>
                <RequestBuilderInput
                  className=""
                  name="customHeaderValue2"
                  type="text"
                  placeholder="Header 2 Value"
                  onChange={changeHandler}
                  value={initioalInput.customHeaderValue2}
                />
              </div>

              <div className="flex flex-row mt-5 ">
                <RequestBuilderInput
                  className=""
                  type="text"
                  name="customHeaderName3"
                  placeholder="Header 3 Name"
                  onChange={changeHandler}
                  value={initioalInput.customHeaderName3}
                />
                <span className="mt-2 px-1">=</span>
                <RequestBuilderInput
                  className=""
                  name="customHeaderValue3"
                  type="text"
                  placeholder="Header 3 Value"
                  onChange={changeHandler}
                  value={initioalInput.customHeaderValue3}
                />
              </div>
            </div>

            {/* {palanName === "شخصی" ? (
              ""
            ) : (
              <>
                {/* <div className="mt-7 flex">
                  <label className="flex">
                    <input
                      type="checkbox"
                      name="screenShot"
                      className="mx-5 w-6 h-6"
                      onChange={changeHandler}
                      value={initioalInput.screenShot}
                    />
                    <span className="pt-2 font-semibold flex">
                      <span>Screenshot</span>{" "}
                      <Tooltip
                        className="py-5 px-5 w-auto"
                        content="If you want to generate a screenshot of the webpage you want to scrape"
                      >
                        <InfoCircle className="mx-2 mt-1 font-semibold" />
                      </Tooltip>
                    </span>
                  </label>
                </div>
                <div className="flex flex-row">
                  <div>
                    <p className="m-5 font-semibold flex">
                      {" "}
                      <span>Width </span>{" "}
                      <Tooltip
                        className="py-5 px-5"
                        content="Browser viewport (window) width in pixel"
                      >
                        <InfoCircle className="mx-2 mt-1 font-semibold" />
                      </Tooltip>
                    </p>
                    <RequestBuilderInput
                      className="mx-4 "
                      type="text"
                      name="width"
                      placeholder="1920"
                      onChange={changeHandler}
                      value={initioalInput.width}
                    />
                  </div>
                  <div>
                    <p className="m-5 font-semibold flex">
                      {" "}
                      <span>Height</span>{" "}
                      <Tooltip
                        className="px-3 py-5"
                        content="Browser viewport (window) height in pixel"
                      >
                        <InfoCircle className="mx-2 mt-1 font-semibold" />
                      </Tooltip>
                    </p>

                    <RequestBuilderInput
                      className="mx-4 "
                      name="height"
                      type="number"
                      placeholder="1080"
                      onChange={changeHandler}
                      value={initioalInput.height}
                    />
                  </div>
                </div> */}

            <p className="m-5 font-semibold flex">
              {" "}
              <span>User Agent </span>
              <Tooltip
                className="py-5 px-5"
                content="use custom user agent to scrape website with that "
              >
                <InfoCircle className="mx-2 mt-1 font-semibold" />
              </Tooltip>
            </p>
            <input
              className=" py-2 mb-2 w-full border border-slate-300 placeholder:text-xs placeholder:p-2 rounded-md "
              placeholder="Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36 "
              name="userAgent"
              onChange={changeHandler}
              value={initioalInput.userAgent}
            />
          </div>
          <div className="w-1/2 flex  justify-center px-5">
            <div className="container relative h-96 ">
              <textarea
                name="formCopy"
                id="textArea"
                value={formCopy}
                // value={`https://api.pushane.ir/api/Scrapper/V1/?api_key=${datakkey}&websiteId=${website}&url=${name}${initialValueTextArea()}${customeValueTextArea()}${confirmPhrase()}`}
                className=" w-full h-full text-green-300 bg-slate-900 border p-5 border-slate-400  rounded-lg shadow-md"
              ></textarea>
              <p
                className="absolute bottom-0 right-0 hover:bg-green-400 px-1 hover:rounded hover:font-semibold my-2 mr-2 hover:px-3 hover:py-2 hover:text-slate-800 text-gray-300 cursor-pointer"
                onClick={textArealChangeHandler}
              >
                Copy
              </p>

              {/*  ////////////////////////////java and block////////////////////////// */}

              <div className="flex flex-row w-full">
                <div className="mt-7 flex w-full">
                  <label className="flex">
                    <input
                      type="checkbox"
                      checked={initioalInput.javaScript}
                      name="javaScript"
                      className="mx-5 mt-2 w-6 h-6"
                      onChange={changeHandler}
                      value={initioalInput.javaScript}
                    />
                    <span className="pt-2 font-semibold flex">
                      {" "}
                      <span>Javascript Rendering </span>{" "}
                      <Tooltip
                        className="py-5 px-5 "
                        style={{ direction: "ltr" }}
                        content="Fetching the URL through a real web browser. Useful for website with lots of JavaScript. Enabled by default"
                      >
                        <InfoCircle className="mx-2 mt-1 font-semibold" />
                      </Tooltip>
                    </span>
                  </label>
                </div>
                <div className="mt-7 flex w-full">
                  <label className="flex">
                    <input
                      type="checkbox"
                      className="mx-5 mt-2 w-6 h-6"
                      onChange={changeHandler}
                      name="blockResource"
                      value={initioalInput.blockResource}
                    />
                    <span className="pt-2 font-semibold flex">
                      {" "}
                      <span> Block Resource </span>{" "}
                      <Tooltip
                        className="py-5 px-5"
                        content="If you want to block images and CSS on the page. Only available with JavaScript rendering"
                      >
                        <InfoCircle className="mx-2 mt-1 font-semibold" />
                      </Tooltip>
                    </span>
                  </label>
                </div>
              </div>
              {palanName === "شخصی" ? (
                ""
              ) : (
                <>
                  <div className="flex flex-row w-full">
                    <div className="mt-7 flex w-full">
                      <label className="flex">
                        <input
                          type="checkbox"
                          className="mx-5 mt-2 w-6 h-6"
                          onChange={changeHandler}
                          name="undetectedScraping"
                          value={initioalInput.undetectedScraping}
                        />
                        <span className="pt-2 font-semibold flex">
                          {" "}
                          <span> Undetected Scraping </span>{" "}
                          <Tooltip
                            className="py-5 px-5" 
                            content="some website detect the automated browser and block bot to scraping so by selecting this option, we can bypass this scenario and scrape that"
                          >
                            <InfoCircle className="mx-2 mt-1 font-semibold" />
                          </Tooltip>
                        </span>
                      </label>
                    </div>
                    <div className="mt-7 flex w-full">
                      <label className="flex">
                        <input
                          type="checkbox"
                          className="mx-5 mt-2 w-6 h-6"
                          onChange={changeHandler}
                          name="bypassCloudflare"
                          value={initioalInput.bypassCloudflare}
                        />
                        <span className="pt-2 font-semibold flex">
                          {" "}
                          <span> Bypass Cloudflare </span>{" "}
                          <Tooltip
                            className="py-5 px-5 "
                            style={{ direction: "ltr" }}
                            content="by checking this option these website that is behind cloudflare will be bypassed and scraped"
                          >
                            <InfoCircle className="mx-2 mt-1 font-semibold" />
                          </Tooltip>
                        </span>
                      </label>
                    </div>
                  </div>

                  {/*/////////////////////// screenShot///////////////////////////// */}

                  <div className="mt-7 flex">
                    <label className="flex">
                      <input
                        type="checkbox"
                        name="screenShot"
                        className="mx-5 mt-2 w-6 h-6"
                        onChange={changeHandler}
                        value={initioalInput.screenShot}
                      />
                      <span className="pt-2 font-semibold flex">
                        <span>Screenshot</span>{" "}
                        <Tooltip
                          className="py-5 px-5 w-auto"
                          content="If you want to generate a screenshot of the webpage you want to scrape"
                        >
                          <InfoCircle className="mx-2 mt-1 font-semibold" />
                        </Tooltip>
                      </span>
                    </label>
                  </div>
                  {/*///////////////////////// width & higth////////////////////////// */}

                  <div className="flex flex-row">
                    <div>
                      <p className="m-5 font-semibold flex">
                        {" "}
                        <span>Width </span>{" "}
                        <Tooltip
                          className="py-5 px-5"
                          content="Browser viewport (window) width in pixel"
                        >
                          <InfoCircle className="mx-2 mt-1 font-semibold" />
                        </Tooltip>
                      </p>
                      <RequestBuilderInput
                        className="mx-4 "
                        type="number"
                        name="width"
                        placeholder="1920"
                        onChange={changeHandler}
                        value={initioalInput.width}
                      />
                    </div>
                    <div>
                      <p className="m-5 font-semibold flex">
                        {" "}
                        <span>Height</span>{" "}
                        <Tooltip
                          className="px-3 py-5"
                          content="Browser viewport (window) height in pixel"
                        >
                          <InfoCircle className="mx-2 mt-1 font-semibold" />
                        </Tooltip>
                      </p>

                      <RequestBuilderInput
                        className="mx-4 "
                        name="height"
                        type="number"
                        placeholder="1080"
                        onChange={changeHandler}
                        value={initioalInput.height}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default RequestBuilder;
