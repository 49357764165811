import React from "react";
import { X } from "react-bootstrap-icons";
import JsonFormatter from "react-json-formatter";

const ModalAddWebsite = ({ modalShow, setModalShow, condition, url }) => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
    directionStyle: { direction: "ltr" },
  };
  return (
    <>
      {modalShow ? (
        <>
          <div className="justify-center items-center flex w-10/12 mx-auto overflow-x-hidden   overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6  h-fit ">
              {/*content*/}
              <div className="border-0 w-full rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-start items-start ">
                  <button
                    className="text-violet-500 background-transparent font-bold w-14 uppercase  text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setModalShow(false)}
                  >
                    <X className="text-red-600   text-2xl hover:border border-red-600 rounded-md shoadow-lg m-2 mx-5 mt-3" />
                  </button>
                </div>
                <div
                  className="flex flex-col items-start p-5 border-solid border-slate-200 rounded-t"
                  style={{ direction: "ltr" }}
                >
                  <div className="flex flex-col items-center justify-end ">
                    <div
                      className="relative "
                      style={{ direction: "ltr" }}
                    >
                      <div className="flex itmes-center justify-start mr-auto flex-col text-left mb-5 px-7">
                        <JsonFormatter
                          json={condition}
                          tabWith={4}
                          jsonStyle={jsonStyle}
                          jsonClassName=""
                        />
                        {/* <p className="text-green-700">
                          <span className="px-2 text-red-600"> "Url" :</span>
                          {url}={url}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalAddWebsite;
