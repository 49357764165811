/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import Input from "../../Components/Input/Input";
import toast from "react-hot-toast";
import { useLogin } from "../../hook/useLogin";
import { AppIndicator, ArrowLeftShort, Circle } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import Captcha from "../../Components/DrCaptcha/Drcaptcha";
// import * as api from "../../apis/DrCaptcha";
import { useCapatcha } from "../../hook/useCaptcha";
import axios from "axios";
import client from "../../utils/AxiosIntesseptor";
import logo from "../../assets/icon/logo-removebg-preview.png";
import Navbar from "../../Components/Navbar/Navbar";
import Loading from "../../Components/Loading/Loading";
import img from "../../assets/icon/Rolling-Loading.svg";

const Login = () => {
  const [formValue, setFormValue] = React.useState({
    username: "",
    password: "",
  });
  const [errorMsg, setErrorMsg] = React.useState({
    usernameMsg: "",
    passwordMsg: "",
  });
  const changeHandler = (e) => {
    setFormValue((prevInput) => {
      return {
        ...prevInput,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { mutate: loginHandler, isLoading: loginLoading } = useLogin();
  const { mutate: checkCaptchaValid } = useCapatcha();

  const validator = () => {
    if (formValue.username === "") {
      setErrorMsg((pravState) => ({
        ...pravState,
        usernameMsg: " نام کاربری خود را وارد کنید ",
      }));
      return false;
    } else {
      setErrorMsg((pravState) => ({
        ...pravState,
        usernameMsg: "",
      }));
    }
    if (formValue.password === "") {
      setErrorMsg((pravState) => ({
        ...pravState,
        passwordMsg: " پسورد خود را وارد کنید",
      }));
      return false;
    } else {
      setErrorMsg((pravState) => ({
        ...pravState,
        passwordMsg: "",
      }));
    }
    return true;
  };
  const [captchaValue, setCaptchaValue] = React.useState({
    dcTextareaResponse: "",
  });
  const submitHandler = (e) => {
    e.preventDefault();
    // let dcTextArea = document.getElementById("dc-textarea-response");
    // // console.log("skjdnl" + dcTextArea)
    // if (dcTextArea.value) {
    //   setCaptchaValue({ dcTextareaResponse: dcTextArea.value });
    //   client.post("/Account/Captcha", captchaValue).then((res) => {
    //     console.log("dfdfdfdfdf", res);
    //   });
    // }

    // if (dcTextArea) {
    //   if (dcTextArea.value) {
    //     // console.log(dcTextArea.value)
    //     setCaptchaValue({dcTextareaResponse:dcTextArea.value})
    //     checkCaptchaValid(captchaValue).then(res => alert(res))
    // api.CheckCaptcha(captchaValue).then((res) => {
    //   console.log(res)
    //     // if (res.data.status === true) {
    //     // // login
    // if (validator()) {
    //   // setErrorMsg;
    //   // loginLoading;
    //   loginHandler(formValue);
    // }
    //     // } else {
    //     //   toast.error("لطفا دوباره تلاش بفرمایید");
    //     // }
    //   });
    // } else {
    //   toast.error("لطفا روی من ربات نیستم کلیک کنید");
    // }
    // } else {
    //   toast.error("پاسخی دریافت نشد لطفا دوباره تلاش کنید");
    // }

    if (validator()) {
      // setErrorMsg;
      // loginLoading;
      loginHandler(formValue);
    }
    // // }
  };

  return (
    <>
      <div className="flex flex-row justify-between p-5 bg-slate-800">
        <img src={logo} alt="pic" className="w-12 h-14 mx-5" />
        <button>
          <a href="/" className="text-gray-400 m-5 flex">
            بازگشت
            <ArrowLeftShort className="mt-1 w-9 text-zinc-50 " />{" "}
          </a>
        </button>
      </div>

      <div className="flex flex-col justify-center items-center md:w-full md:h-screen h-screen  bg-slate-800">
        <div className="bg-transparent  flex flex-col lg:flex lg:items-center lg:justify-center  w-3/4 h-96 lg:w-2/5 lg:h-3/4">
          <div className="w-full h-fit flex flex-col justify-center items-center border border-slate-700 rounded-2xl ">
            <p className="text-xl my-5 mt-5 text-zinc-50 tracking-wider font-semibold">
             
              ورود به حساب کاربری
            </p>
            <form onSubmit={submitHandler} className="my-5 w-3/4">
              <Input
                placeholder="نام کاربر"
                name="username"
                type="text"
                value={formValue.username}
                onChange={changeHandler}
                error={errorMsg.usernameMsg}
                className="mb-3 w-full  px-4"
              />
              <Input
                placeholder="رمز عبور"
                name="password"
                type="password"
                value={formValue.password}
                onChange={changeHandler}
                error={errorMsg.passwordMsg}
                className="w-full -mb-5 px-4"
              />
            </form>
            <div className="my-2 mt-5  flex w-fit h-fit justify-center items-center">
              <Captcha />
            </div>
            {loginLoading ? (
              <button className="relative flex justify-center items-center bg-transparent mt-3 rounded-3xl border-2  w-3/4 mx-auto border-violet-600 py-5 bg-violet-600 text-zinc-100">
                <img src={img} alt="pic" className="w-7 absolute" />
              </button>
            ) : (
              <button
                onClick={submitHandler}
                type="submit"
                className=" bg-transparent mt-3 rounded-3xl border-2 mb-3 w-3/4 mx-auto border-violet-600 py-3 bg-violet-600 text-zinc-100"
              >
                ورود
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;

// {/* <div className="flex flex-row justify-between p-5 bg-slate-800">
// <img src={logo} alt="pic" className="w-12 h-14 mx-5" />
// <button>
//   <a href="/" className="text-gray-400 m-5 flex">
//     بازگشت
//     <ArrowLeftShort className="mt-1 w-9 text-zinc-50 " />{" "}
//   </a>
// </button>
// </div>

// <div className="bg-slate-800  min-h-screen flex flex-col justify-center items-center md:flex md:flex-col md:justify-center md:items-center lg:flex lg:flex-col lg:items-center lg:justify-center">
// {/* <div className="flex justify-center items-center md:flex md:justify-center md:items-center lg:flex lg:items-center lg:justify-center "> */}
//   <div className="  border p-5 rounded-3xl border-slate-600 text-zinc-100 w-3/4 h-3/4 md:w-44 md:h-3/4 lg:w-2/4 lg:h-3/6 ">
//     <div>
//     <p className="text-xl tracking-wide font-semibold">   ورود به حساب کاربری </p>
//     {/* <form onSubmit={submitHandler}> */}
//     <Input
//       placeholder="نام کاربر"
//       name="username"
//       type="text"
//       value={formValue.username}
//       onChange={changeHandler}
//       error={errorMsg.usernameMsg}
//     />

//     <Input
//       placeholder="رمز عبور"
//       name="password"
//       type="password"
//       value={formValue.password}
//       onChange={changeHandler}
//       error={errorMsg.passwordMsg}
//     />
//     {/* </form> */}
//     {/* <p className="pt-5">
//   ثبت نام نکرده اید؟
//   <a href="/Register" className="text-base text-gray-400 underline">
//     وارد شوید
//   </a>
// </p> */}
//     {/* <Link to="/dashboard"> */}
//     <div className="my-2 flex justify-center items-center">
//       <Captcha />
//     </div>

//     <button
//       onClick={submitHandler}
//       type="submit"
//       className="bg-violet-500 text-gray-100 w-full rounded-3xl py-3 "
//     >
//       ورود
//     </button></div>
//   </div>
// {/* </div> */}
// </div> */}
