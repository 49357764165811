import * as api from "../apis/login";
import { useMutation } from "react-query";
import { queryClient } from "../index";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const useLogin = () => {
  const navigate = useNavigate();
  return useMutation(api.login, {
    onSuccess: (response, values) => {
      if (response.status) {
        queryClient.setQueryData("login", {
          password: response.item.password,
          username: response.item.username,
          // apiKey: response.item.apiKey,
        });
        localStorage.setItem("_authToken", response.item.token);
        localStorage.setItem("_refreshToken", response.item.refreshToken);
        localStorage.setItem("apiKey" , response.item.apiKey)
        localStorage.setItem("planName" , response.item.userPlan.planName)
        localStorage.setItem("countWebsitePlan" , response.item.userPlan.countWebsitePlan)
        localStorage.setItem("websiteUser" , response.item.websiteUser)
        localStorage.setItem("proxyAll" , response.item.proxyAll)


       

        navigate("/dashboard");
      } else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  });
};
export { useLogin };
