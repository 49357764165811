import React, { useEffect } from "react";
import { useProxyWebsite } from "../../hook/useProxyWebsite";
import List from "../List/List";
const Modal = ({ modalShow, setModalShow, children }) => {
  // const [proxyList, setProxyList] = React.useState( ProxyWebsite?.map((item) => ProxyWebsite[0].pProxies));

  const column = [
    { path: "country", name: "country" },
    { path: "ip", name: "ip" },
    { path: "port", name: "port" },
    { path: "tryCount", name: "tryCount" },
    
  ];
  const data = [
    {
      country: "CN",
      createDate: "2022-07-11T13:04:05.024Z",
      ip: "211.138.6.37",
      pProxyId: "62cc1fd44b7213c2d0375e06",
      port: "9091",
      tryCount: 0,
    },
  ];
  const { data: proxyListItmes } = useProxyWebsite({
    page: 1,
    itemcount: 20,
    orderBy: "",
    filter: "",
  });
  
useEffect(()=> {
  // console.log("jkkkkkkkkkkkkkkkkkkkkkkkk" ,proxyListItmes.message)
  // proxyListItmes[0]['pProxies']
  },[])

// const ProxyData = () =>{
//   debugger;
//   if(proxyListItmes.status){
//     return proxyListItmes[0]['pProxies']
//   }else{
//     return proxyListItmes
//   }
// }

  return (
    <>
      {modalShow ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto max-w-3xl h-fit ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start p-5 border-solid border-slate-200 rounded-t">
                  <div className="flex flex-col items-center justify-end ">
                    <div className="relative p-6 flex-auto">
                      <List data={data} columns={column}></List>
                      
                    </div>
                    <div>
                      <button
                        className="text-violet-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setModalShow(false)}
                      >
                        بستن
                      </button>
                      <button
                        className="bg-violet-500 text-white active:bg-rose-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setModalShow(false)}
                      >
                        دخیره
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;

// <form className="space-y-6">
// <div>
//   <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
//     judgeUrl
//   </label>
//   <input
//     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//     placeholder="name@company.com"
//     required
//   />
// </div>
// <div>
//   <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
//     websiteUrl
//   </label>
//   <input
//     type=""
//     name=""
//     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//     required
//   />
// </div>
// </form>
