import * as api from "../apis/ProxyWebsite";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../index";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const useProxyWebsite = (values) => {
  return  useQuery("ProxyWebsiteList", () => api.getProxyWebsiteList(values))
 
};

export { useProxyWebsite };
