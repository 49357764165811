import React, { useEffect, useState } from "react";
import Wrapper from "../../../Components/HOC/Wrapper";
import { useProxyLogList } from "../../../hook/useProxyLog";
import { useLocation, useNavigate } from "react-router";
import ProxyList from "../../../Components/List/ProxyList";
import Pagination from "../../../Components/Pagination";
import ModalParameters from "../../../Components/Moudal/ModalPrameters";

const Log = ({}) => {
  const {
    data: WebsiteProxy,
    mutate: getWebsiteById,
    isLoading: webSiteInfoLoading,
  } = useProxyLogList();
  // setData(res.data);
  const [pagination, setPagination] = useState(1);

  const btnpagination = (id) => {
    setPagination(id);
    // console.log(Math.ceil(WebsiteProxy?.items?.count / 10));
  };
  const location = useLocation();
  const [data, setData] = useState([]);
  const [dataPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const Showpost = currentPage * dataPerPage;
  const totalShowPost = currentPage * dataPerPage;
  useEffect(() => {
    if (location.search) {
      let splitedLocation = location.search.split("&");
      let id = splitedLocation[0].replace("?id=", "");
      getWebsiteById({
        page: pagination,
        pageSize: 10,
        filter: `pWebsiteId=${id}`,
      });
    }
  }, [location, pagination]);

  const columns = [
    { name: "Url", path: "url" },
    { name: "آدرس Ip", parent: "pProxy", path: "ip" },
    { name: "کشور", parent: "pProxy", path: "country" },
    { name: "نتیجه", path: "result" },
    { name: "تاریخ و ساعت", path: "createDate" },
  ];

  useEffect(() => {
    setData(WebsiteProxy?.items?.data.pProxy);
  }, [WebsiteProxy]);

  const next = () => setCurrentPage((prevState) => prevState + 1);
  const Previous = () => setCurrentPage((prevState) => prevState - 1);

  const [modalShow, setModalShow] = React.useState(false);
  const [modal, setModal] = useState("");
  
useEffect(()=>{
  setModal(WebsiteProxy?.items?.data[0]?.conditions)
  console.log(modal)
})



  return (
    <Wrapper>
      <ProxyList
        columns={columns}
        data={WebsiteProxy?.items?.data}
        Loading={webSiteInfoLoading}
        onClickParametr={() => setModalShow(true)}
      />

      <ModalParameters modalShow={modalShow} setModalShow={setModalShow} condition={modal} />

      <Pagination
        Showpost={Showpost}
        totalShowPost={totalShowPost}
        Previous={Previous}
        next={next}
        currentPage={currentPage}
        totalData={WebsiteProxy?.items?.count}
        btnpagination={btnpagination}
        // pageCount={pageCount}
      />
    </Wrapper>
  );
};

export default Log;
