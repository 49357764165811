import React from "react";
import {
  Trash,
  PencilSquare,
  WrenchAdjustableCircle,
  Display,
  FileText,
} from "react-bootstrap-icons";
import LoadingInfo from "../../Components/Loading/Loading";
import { useNavigate } from "react-router";

const List = ({
  name,
  data,
  columns,
  editBtn,
  deletebtn,
  onClick,
  Loading,
  noBtn,
  className,
  lastUpdate,
  DisplyProxyLog,
  WebsiteList,
}) => {
  const Navigate = useNavigate();
  const commaNumber = require("comma-number");

  // const result1 = commaNumber(proxyAll)

  const navigateHandler = (id, proxyWebsiteId) => {
    Navigate(`/requestbuilder?id=${id}&proxyWebsiteId=${proxyWebsiteId}`);
  };
  const navigateProxy = (id) => {
    Navigate(`/Log?id=${id}`);
  };



  return (
    <div className="relative w-full direction overflow-x-auto overflow-y-auto shadow-sm border-noun rounded-md  ">
      <table className="md:w-full text-sm h-fit  text-gray-500 border-collapse mt-5">
        <thead>
          <tr className="">
            {/* <th></th> */}
            {columns?.map((item) => (
              <th
                key={item.path}
                className={`${className}text-rose-800 mt-5 border border-gray-100 py-4`}
              >
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* <tr></tr> */}

          {Loading ? (
            <tr>
              <td>
                <LoadingInfo  />
              </td>
            </tr>
          ) : (
            data?.map((rowData, index) => (
              <tr
                key={index}
                className="h-20 bg-white border-b  border-gray-200"
                
              >
                <th className="px-2" >{index + 1}</th>
                {columns?.map(({ path }) => {
                  return (
                    <>
                      <td
                      style={{direction:"ltr"}}
                        className=" text-slate-700 font-medium whitespace-nowrap text-center border-r "
                        key={path}
                      >
                        {rowData[path]}
                      </td>
                      {noBtn && (
                        <>
                          <td>
                            <button
                              className="shadow-md rounded-md text-gray-500 px-4 py-2 text-xs"
                              onClick={() => {
                                editBtn(rowData);
                              }}
                            >
                              <p className="flex ">
                                ویرایش
                                <PencilSquare className="mx-2" />
                              </p>{" "}
                            </button>
                          </td>
                          <td>
                            <button
                              className="shadow-md rounded-md text-gray-500 px-4 py-2 text-xs"
                              onClick={() => {
                                deletebtn(rowData);
                              }}
                            >
                              <p className="flex">
                                حذف
                                <Trash className="mx-2" />
                              </p>
                            </button>
                          </td>

                          <td>
                            {/* <Link
                                to={`/requestbuilder?pwebsiteid=${navurl}`}> */}
                            <button
                              className="shadow-md rounded-md text-gray-500 px-4 py-2 text-xs"
                              onClick={() =>
                                navigateHandler(
                                  rowData?.pWebsiteId,
                                  rowData?.pProxyWebsiteId
                                )
                              }
                            >
                              <p className="flex">
                                Request builder
                                <WrenchAdjustableCircle className="mx-2 text-gray-500 " />
                              </p>
                            </button>
                            {/* </Link> */}
                          </td>

                          <td>
                            {/* <Link
                                to={`/requestbuilder?pwebsiteid=${navurl}`}> */}
                            <button
                              className="shadow-md rounded-md text-gray-500 px-4 py-2 text-xs"
                              onClick={() =>
                                navigateProxy(
                                  rowData?.pWebsiteId,
                                  rowData?.pProxyWebsiteId
                                )
                              }
                            >
                              <p className="flex">
                                Log
                                <FileText className="mx-2 text-gray-500 " />
                              </p>
                            </button>
                            {/* </Link> */}
                          </td>

                          <td>
                            <p
                              className="shadow-md rounded-md text-gray-500 px-4 py-2 text-xs"
                              // onClick={DisplyProxyLog}
                            >
                              تعداد پروکسی ها :
                              {/* <Display className="mx-2 text-gray-500 " />
                               */}
                              {commaNumber(rowData?.proxyCount)}
                              {/* {} */}
                              {/* {DisplyProxyLog} */}
                            </p>
                          </td>
                          {/* {proxy?.map((item , index) =>{
                              return ( */}
                          <td >
                            <p
                              className="shadow-md rounded-md text-gray-500 px-2 py-2 text-xs"
                              // onClick={DisplyProxyLog}
                            >
                              <span className="flex">
                                <span>آخرین آپدیت : {rowData?.lastUpdate}</span>
                                <Display className="mx-2 text-gray-500 " />
                              </span>
                              {/* {/* {rowData[lastUpdate]} */}
                              {/* {lastUpdate?.map((rowData[lastUpdate])=>())} */}
                              {/* WebsiteList?.map((item) => item.lastUpdate) */}
                            </p>
                          </td>
                          
                        </>
                      )}
                    </>
                  );
                })}
              </tr>
            ))
          )}

          <div className="flex items-end justify-end w-full"></div>
        </tbody>
      </table>
    </div>
  );
};
export default List;
