import * as api from "../apis/Website";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../index";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const useWebsiteList = (values) => {
  return useQuery("WebsiteList", () => api.getWebsiteList(values));
};

const useDeleteWebsite = () => {
  return useMutation(api.deleteWebsite, {
    onSuccess: (response, pWebsiteId) => {
      if (response.status) {
        const lastData = queryClient.getQueryData("WebsiteList");
        const newData = lastData.filter(
          (item) => item.pWebsiteId !== pWebsiteId
        );
        queryClient.setQueryData("WebsiteList", newData);
        toast.success(response.message);
      } else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  });
};
const useGetWebsiteById = () => {
  return useMutation(api.getWebsiteById, {
    onSuccess: (response, websiteId) => {
      if (response.status) {
        queryClient.setQueriesData(["WebsiteInfo",websiteId]);
      } else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  });
};

const useUpdateWebsite = () => {
  return useMutation(api.updatewebsite, {
    onSuccess: (response, values) => {
      if (response.status) {
        let lastData = queryClient.getQueryData("WebsiteList");
        lastData.forEach((item) => {
          // console.log(values)
          if (item.pWebsiteId === values.pWebsiteId) {
            item.websiteUrl = values.websiteUrl;
            item.judgeUrl = values.judgeUrl;
          }
          return item;
        });
        toast.success("وبسایت با موفقیت ویرایش شد.");
      } else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  });
};

const useCreateWebsite = (values) => {
  return useMutation(api.createWebsite, {
    onSuccess: (response, values) => {
      if (response.status) {
        let lastData = queryClient.getQueryData("WebsiteList");
        lastData.push(response.item);
        toast.success("   وبسایت جدید با موفقیت افزوده شد .");
      } else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  });
};

export { useWebsiteList, useDeleteWebsite, useCreateWebsite, useUpdateWebsite,useGetWebsiteById };
