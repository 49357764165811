import React, { useState } from "react";
import { Link } from "react-router-dom";
import img from "../../assets/icon/align-center.png";
import { useNavigate } from "react-router";

const Sidebar = ({open, setOpen}) => {
 
let Navigate = useNavigate()

  const Menu = [
    { title: "داشبورد", image: "menu", url: "/dashboard" },
    { title: " وب سایت ها", image: "list", url: "/website" },
    { title: "تنظیمات", image: "setting", url: "/setting" },
    // {
      // title: "خروج",
      // image: "sign-out",
      // url: "/localhost:3000",
    // },
  ];

  // logout = () => {
  //   location.href = 'localhost:3000';
  // }

  // const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex h-full flex-row absolute">
      <div
        className={`${
          open ? " w-56" : " w-20"
        } duration-300  min-h-screen bg-slate-800 relative`}
      >
        <div
          className={`grid grid-cols-2 border-b border-gray-600 shadow-sm pb-2 ${
            open ? "" : "border-none"
          }`}
        >
          
          {/* <img
            alt="dashboard"
            src={""}
            className=" flex items-center gap-x-4 w-9  mt-10 p-1 mr-3"
          /> */}
          {/* <div className="flex items-center justify-between w-full"> */}
          <div className={`${open && "flex justify-center items-center ml-14"}`} >
          <img
              alt="dashboard"
              src={img}
              className={`${
                open && "rotate-180"
              }  rounded-full absolute cursor-pointer w-7 mr-5  top-11 `}
              onClick={() => setOpen(!open)}
            />
           

          </div>
          <div className="">
              <h1
              className={`${
                !open && "hidden"
              } text-xl font-bold mt-12 text-white `}
            >
              Pushane.ir
            </h1>

          </div>
          </div>
         
        {/* </div> */}
        {/* <img
          alt="dashboard"
          src={img}
          className={`${
            open && "rotate-180"
          } z-1 rounded-full absolute cursor-pointer w-7 -left-3 top-9`}
          onClick={() => setOpen(!open)}
        /> */}
        <ul className={` ${open ? "pt-6" : "pt-20"}`}>
          {Menu.map((menu, index) => (
            <li
            onClick={() => Navigate(menu.url)} 
              key={index}
              className="flex items-center gap-x-4 cursor-pointer text-gray-100 font-semibold tracking-wide whitespace-nowrap mt-5 p-2 hover:bg-opacity-60 hover:backdrop-filter hover:bg-white hover:backdrop-blur-lg hover:text-white hover:rounded-md hover:mx-2"
            >
              <img
                alt="icon"
                src={require("../../assets/icon/" + menu.image + ".png")}
                // src={`/image/${menu.src}.png`}
                className="w-5 mr-3 "
              />

              <span
                className={`${!open && "hidden"} origin-right duration-200  `}
              >
                {menu.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
