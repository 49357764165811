import * as api from "../apis/Register";
import { useMutation } from 'react-query';
import { queryClient } from "../index"
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";



const useRegister = () => {
  const navigate = useNavigate()
  return useMutation(api.Register, {
    onSuccess: (response, values) => {
      if (response.status) {
        queryClient.setQueryData("Register", values);
       
        navigate("/login");
      } else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  })
}
export { useRegister }


