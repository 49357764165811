import { menu } from "@material-tailwind/react";
import React, { useState } from "react";
// import Link from "next/link";
import { Link } from "react-router-dom";
// import IoMenuOutline from 'react-icons/io'
import logo from "../../assets/icon/logo-removebg-preview (2).png";

export const MainNav = () => {
  const singInHandler = () => {};
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="bg-white w-full">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen  &&
       <MobileMenu>{navLinks}</MobileMenu>
       }
    </div>
  );
};

const menue = [{text: "خانه"  , url:"/"},
{text: "پلن ها"  , url:"#pricingTable"},
{text: " دمو"  , url:"#demo"},
// {text: "پوشانه"  , url:"https://api.whatsapp.com/send?phone=989124249313&text=سلام وقت بخیر راهنمایی در خصوص پوشانه"},
{text: "تماس با ما "  , url:"https://api.whatsapp.com/send?phone=989124249313&text=%D8%B3%D9%84%D8%A7%D9%85%D8%8C%D9%88%D9%82%D8%AA%20%D8%A8%D8%AE%DB%8C%D8%B1"} ];


const navLinks = menue.map((menue ,index, className) => (
  <a
    key={index}
    className={`flex justify-center items-center no-underline text-zinc-100 px-5 text-base hover:text-violet-600 ${className}`}
    // href="https://api.whatsapp.com/send?phone=989124249313&text=سلام وقت بخیر برای درخواست دمو راهنمایی میخواستم"
    href={`${menue.url}`}
  >
    {menue.text}
  </a>
));

const Navbar = ({ menuOpen, setMenuOpen,className }) => (
  <div className={` flex bg-slate-800  h-24 justify-center items-center pt-12 p-5 shadow-lg ${className}`}>
    <div className=" md:grid md:grid-cols-3 gap-7 w-full">
      <div className=" text-zinc-100 flex justify-center items-center ">
        <img src={logo} alt="pic" className="hidden md:block lg:block w-40"></img>
      </div>
      <nav className=" hidden md:block space-x-6 ">
        <div className="md:flex px-2  md:justify-center md:items-center flex justify-end items-end">{navLinks}</div>
      </nav>
      <Link to="/Login" className=" md:flex md:justify-center md:items-center  text-zinc-100 hover:text-violet-800 cursor-pointer">
          <button className=" mb-5 md:mb-3 hover:bg-violet-500  hover:text-white py-2 px-4 rounded-lg ml-2 border border-violet-500 hover:border-transparent">
            ورود
          </button> 
          {/* </a> */}
          </Link>
          {/* ثبت نام */}
       
      
    </div>

    <button
      type="button"
      aria-label="Toggle mobile menu"
      onClick={() => setMenuOpen(!menuOpen)}
      className="rounded md:hidden focus:outline-none focus:shadow-lg  text-slate-600"
    >
      <IoMenuOutline menuOpen={menuOpen} />
    </button>
  </div>
);

const MobileMenu = ({ children }) => (
  <nav className="p-4 flex flex-col bg-slate-700 space-y-3 md:hidden">{children}</nav>
);

const IoMenuOutline = ({ menuOpen }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`transition duration-100 ease-linear mb-3 h-10 text-zinc-100 w-10 ${
      menuOpen ? "transforrm rotate-90" : ""
    }`}
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillrule="evenodd"
      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
      cliprule="evenodd"
    />
  </svg>
);

export default MainNav;
