import client from "../utils/AxiosIntesseptor";

const getWebsiteList = async (values) => {
    let { data } = await client.post('/Website/List' , values);
    return data.items;
  };
  const getWebsiteById = async (websiteId) => {
    let { data } = await client.get(`/Website/Edit?websiteid=${websiteId}`);
    return data;
  };
  const deleteWebsite = async (id) => {
    let { data } = await client.post(`/Website/Delete?pWebsiteId=${id}`)
    return data
  }
  const updatewebsite = async (values) => {
    let { data } = await client.post("/Website/Edit", values);
    return data
  }
  
  const createWebsite = async (values) => {
    let { data } = await client.post('/Website/Edit', values)
    return data
  }

  export { getWebsiteList, deleteWebsite, createWebsite, updatewebsite,getWebsiteById}