import React from "react";
import LoadingInfo from "../../Components/Loading/Loading";
import ModalParameters from "../Moudal/ModalPrameters";
import JsonFormatter from "react-json-formatter";

const ProxyList = ({
  item,
  Loading,
  name,
  data,
  className,
  columns,
  ip,
  createDate,
  onClick,
  conditions,
  Parametrerbtn,
  onClickParametr,
}) => {


  return (
    <div className="relative direction mx-auto w-full bg-white mt-5 overflow-y-auto table-auto overflow-x-scroll rounded-md shadow-sm border  ">
      <table className="w-full h-fit text-gray border-none">
        <thead >
          <tr>
            <th>ردیف</th>
            {columns?.map((item) => (
              <th
                key={item.path}
                className={`${className} rounded-lg mt-5 px-4 py-4`}
              >
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          
        
          {Loading ? (
      
              <tr>
              <td colSpan={10} height={20}>
                <LoadingInfo />
              </td>
            </tr>
   
            ) : (
              data?.map((rowData, index) => {
                return (
                  <tr
                    key={index}
                    className="px-4 text-center border rounded-lg "
                  >
                    <th className="text-center px-4 py-4 text-gray-600">
                      {index + 1}
                    </th>
                    {columns.map(({ parent, path }) => {
                      // console.log(rowData?.conditions)
                      
                      return parent ? (
                        <td className="py-4 px-4 text-gray-500  text-center">
                          {rowData[parent][path]}
                        </td>
                      ) : (
                        <td className="py-4 px-4 text-center text-gray-500 ">
                          {[path][0] === "url"
                            ? rowData.url.substring(0, 60) + "..."
                            : rowData[path]}
                        </td>
                      );
                    })}

                    <td className="py-4 px-4 mt-5 text-center">
                      <button
                        className="shadow-md rounded-md text-gray-500 px-4 py-2 text-xs"
                        onClick={onClickParametr}
                      >
                        پارامترها
                      </button>
                      {/* <ModalParameters modalShow={modalShow} /> */}
                     
                    </td>
                  </tr>
                );
              })
            )}
          
        </tbody>
      </table>
    </div>
  );
};

export default ProxyList;








