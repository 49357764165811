import React from "react";
import loadingUI from "./loading.svg";
const Loading = (className) => {
  return (
    <div className="loading flex justify-center items-center">
      <img src={loadingUI} alt="loading ..." className={className} />
    </div>
  );
};

export default Loading;
