import React from "react";
import telegram from "../../assets/icon/telegram (1).png";
import linkedin from "../../assets/icon/linkedin (1).png";
import whatsapp from "../../assets/icon/whatsapp (1).png";
import instagram from "../../assets/icon/instagram (1).png";
import { GeoAlt } from "react-bootstrap-icons";
import logo from "../../assets/icon/logo-removebg-preview (3).png";

const Footer = () => {
  return (
    <>
      <footer class="p-4 bg-violet-300 rounded-lg shadow md:px-6 md:py-8 ">
        <div class="sm:flex sm:items-center sm:justify-between">
          <ul class="flex flex-wrap items-center justify-center mb-6 text-sm text-gray-700 sm:mb-0 ">
            <li>
              <a
                href="#index"
                class="mr-4 hover:underline md:mr-6  font-bold"
              >
                خانه
              </a>
            </li>
            <li>
              <a
                href="#demo "
                class="mr-4 hover:underline md:mr-6 font-bold"
              >
                درخواست دمو
              </a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=989124249313&text=سلام وقت بخیر برای درخواست دمو راهنمایی میخواستم "
                class="mr-4 hover:underline md:mr-6  font-bold"
              >
                درباره ما
              </a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=989124249313&text=%D8%B3%D9%84%D8%A7%D9%85%D8%8C%D9%88%D9%82%D8%AA%20%D8%A8%D8%AE%DB%8C%D8%B1 "
                class="mr-4 hover:underline md:mr-6  font-bold"
              >
                ارتباط با ما
              </a>
            </li>
          </ul>
          <a href="https://pushane.ir/" className="flex items-center justify-center mb-4 sm:mb-0">
            <span className="self-center text-2xl text-gray-800 font-semibold whitespace-nowrap ">
              Pushane.ir
            </span>{" "}
            <img src={logo} className="mr-3 w-14" alt=" Logo" />
          </a>
        </div>


        
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-700 sm:text-center items-center flex justify-center dark:text-gray-400">
          2022 <a href="https://pushane.ir/">© Pushane.ir</a>. All Rights
          Reserved
        </span>
        <div className="flex justify-center items-center pt-5 text-sm text-gray-700">Version 1.6.3</div>
      </footer>
    </>
  );
};

export default Footer;

/* <div className="border-l border-violet-500 border-separate pt-5">dfgcdhgf</div>
        <div>lkjdlkvmsf</div>
        <div className="border-r border-violet-500 border-separate pt-5">dfgdfgd</div>  */

//     <div className="flex">
//     <img src={linkedin} alt="pic" className="w-7 mx-2" />
//     <img src={whatsapp} alt="pic" className="w-7 mx-2" />
//     <img src={instagram} alt="pic" className="w-7 mx-2" />
//     <img src={telegram} alt="pic" className="w-7 mx-2" />
//   </div>
//   <div className="flex text-gray-700 font-bold">
//     <p className="px-5">خانه</p>
//     <p className="px-5">دمو</p>
//     <p className="px-5">درباره ما</p>
//     <p className="px-5">تماس با ما</p>
//   </div>
//   <div className="flex text-gray-600 text-sm">
//     <GeoAlt className="mx-2" /> خیابان سی-یکم ، نبش ابن سینا ، ساختمان
//     کهکشان نور
//   </div>
//   <span className="text-xs">copyright @ 2022</span>
