import React, { useEffect } from "react";
import { Asterisk, Display } from "react-bootstrap-icons";
import Input from "../../Components/Input/Input";

const ModalAddWebsite = ({
  setModalAddWebsite,
  modalAddWebsite,
  onChange,
  valueUrl,
  valueJudge,
  onSubmit,
  onMouseEnter,
  onMouseLeave,
  showMoreInfo,
  readOnly,
  content
  
}) => {
  return (
    <>
      {modalAddWebsite ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6  h-fit ">
              {/*content*/}
              <div className="border-0 w-full rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-col items-start p-5 border-solid border-slate-200 rounded-t">
                  {/* {editbtn ? ( */}
                  {/* <> */}
                  {/* <p
                    className="flex cursor-pointer"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  >
                    <Asterisk className="text-red-600 w-5 h-3 mt-1 mx-2" />{" "}
                    <span className="text-gray-400 hover:bg-none hover:">
                      توضیحات بیشتر
                    </span>
                  </p>
                  <>
                    {showMoreInfo ? (
                      <p className="pr-5 pt-2 text-sm">
                        url در بخش ویرایش غیر قابل تغیر است <br />judge url
                        لینکی که سرویس های ما صحت پروکسی های انتخاب شده را برای
                        وبسایت با استفاده از آن می سنجند، محتوای این لینک می
                        تواند تصویری کوچک و یا فایل استایل کوچکی از وبسایت مورد
                        نظر باشد.
                      </p>
                    ) : (
                      ""
                    )}
                  </> */}
                  {/* </> */}
                  {/* ) : null} */}

                  <div className="flex flex-col items-center justify-end ">
                    <div
                      className="relative p-6 flex-auto"
                      style={{ direction: "ltr" }}
                    >
                   <div className="flex flex-col">
                   <label className="text-gray-700 font-semibold tracking-wide">
                        Website Url :
                      </label>
                      <p className="flex">
                        {" "}
                        <Asterisk className="text-red-600  w-2 h-3 mt-1 mx-2" />
                        {content ?(
                        <span className="text-sm m-2 text-gray-400" style={{direction:"rtl"}}>
                          url وبسایت مورد نظر خود را وارد کنید
                        </span>
                        ) : (<span className="text-sm m-2 text-gray-400"  style={{direction:"rtl"}}> Url غیر قابل تغییر میباشد</span> )}
                      </p>
                      <Input
                        className="placeholder:https://url placeholder:text-gray-600 w-96 text-black p-2 mt-2 mb-2 "
                        name="websiteUrl"
                        type="text"
                        value={valueUrl}
                        onChange={onChange}
                        readOnly={readOnly}
                      />

                   </div>
                      
                  <div className="flex flex-col" >
                  <label className="text-gray-700 font-semibold tracking-wide">
                        Judge Url :
                      </label>
                      <p className="flex">
                        {" "}
                        <Asterisk className="text-red-600 w-2 h-3 mt-2 mx-2" />
                    
                        <span className="text-xs w-96 text-justify p-2 text-gray-400"  style={{direction:"rtl"}}>
                        لینکی که سرویس های ما صحت پروکسی های انتخاب شده را برای
                        وبسایت با استفاده از آن می سنجند، محتوای این لینک می
                        تواند تصویری کوچک و یا فایل استایل کوچکی از وبسایت مورد
                        نظر باشد.
                        </span>
                      </p>

                      <Input
                        className="mt-2 w-96 text-black p-2"
                        name="judgeUrl"
                        type="text"
                        value={valueJudge}
                        onChange={onChange}
                      />
                  </div>
                    </div>








                    <div>
                      <button
                        className="text-violet-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setModalAddWebsite(false)}
                      >
                        بستن
                      </button>
                      <button
                        className="bg-violet-500 text-white active:bg-rose-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={onSubmit}
                      >
                        دخیره
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalAddWebsite;
