import React from "react";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import { Toaster } from "react-hot-toast"
import Index from "./Pages/Index";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Login/Register";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Setting from "./Pages/Dashboard/Setting";
import Loglist from "./Pages/Dashboard/Loglist";
import Website from "./Pages/Dashboard/Website"
import RequestBuilder from "./Pages/RequestBuilder/RequestBuilder";
import Log from "./Pages/Dashboard/Log/Log";







function App() {






  return (
 <BrowserRouter>
 <Toaster />
  <Routes>
    <Route path="/" element={<Index />} />
    <Route path="login" element={<Login />} />
    <Route path="register" element={< Register/>} />
    <Route path="dashboard" element={<Dashboard/>} />
     <Route path="setting" element={<Setting/>} />
     <Route path="Loglist" element={<Loglist/>} />
     <Route path="website" element={<Website/>} />
     <Route path="requestbuilder" element={<RequestBuilder/>} />
     <Route path="Log" element={<Log/>} />

  
  </Routes>
 </BrowserRouter>
  );
  }
export default App;
