import React from "react";
import Input from "../../Components/Input/Input";
import { useRegister } from "../../hook/useRegister";
import toast from "react-hot-toast";

const Register = () => {

const [formValue , setFormValue ] =React.useState({
  pUserId: "",
  username: "",
  password: "",
  confirmPassword: ""
})

const changeHandler = (e) => {
  setFormValue((prevInput) => {
    return {
      ...prevInput,
      [e.target.name]: e.target.value,
    };
  });
};

const { mutate: registerHandler, isLoading: loginLoading } = useRegister();

const validator = () => {
  if (formValue.username === "") {
    toast.error("نام کاربری خالی نمیباشد");
    return false;
  } else if (formValue.password === "") {
    toast.error(" رمز عبور وارد نشده است ");
    return false;
  }
  return true;
};

const submitHandler = (e) => {
  e.preventDefault();
  if (validator) {
    registerHandler(formValue)
    
  }
};


  return (
    <div className="bg-slate-800 h-screen flex justify-center items-center">
      <div className="w-96 h-3/4 rounded-lg border-noun text-zinc-100 mt-20 ">
        <p clssName="font-bold text-2xl">ثبت نام </p>
      <form onSubmit={submitHandler}>
      <Input placeholder="نام کاربری" onChange={changeHandler} type="text" name="username" value={formValue.username}  />
        <Input placeholder="رمز عبور" onChange={changeHandler} type="text" name="password" value={formValue.password} />
        <Input placeholder="تکرار رمز عبور" onChange={changeHandler} type="text" name="confirmPassword" value={formValue.confirmPassword}/>
        <p className="pt-5">
          قبلا ثبت نام کرده اید ؟
          <a href="/Login" className="text-base text-gray-400 underline">
            ورود به حساب کاربری
          </a>
        </p>

        <button className="bg-violet-500 text-gray-100 w-full rounded-3xl py-3 mt-5">
          ثبت نام
        </button>
      </form>
      </div>
    </div>
  );
};

export default Register;
