import * as api from "../apis/proxyLog";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../index";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

// const useProxyLogList = (values) => {
//   return useQuery("ProxyList", () => api.getProxyLogList(values));
// };


const useProxyLogList = () => {
  return useMutation(api.getProxyLogList, {
    onSuccess: (response, websiteId) => {
      if (response.status) {
        queryClient.setQueriesData(["WebsiteProxy",websiteId]);
      } else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  });
};




export { useProxyLogList };
