import React from "react";
import Button from "../../../Components/Button/Button";
import imgProfile from "../../../assets/icon/Profile.png";
// import Test from "../../../Components/Test";
import { Link, useNavigate } from "react-router-dom";
import { Power } from "react-bootstrap-icons";
const NavbarDash = () => {
  const [show, setShow] = React.useState(false);

  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="  relative h-9 bg-slate-800 w-full flex justify-end items-end ">
      <span className="text-orange-100 font-semibold pb-1 ">خروج</span>
      <button
        onClick={logout}
        className="w-50 m-2  rounded-full "
        // onMouseEnter={() => setShow(<Test />)}
      >
        <Power className="text-zinc-50 w-8 ml-4 font-bold" />
        {/* <img alt="p" src={imgProfile} className="w-9" /> */}
      </button>
    </div>
  );
};

export default NavbarDash;
