import React from "react";
import Wrapper from "../../Components/HOC/Wrapper";
import nn from "../../assets/icon/Hello-rafiki.png";
import Card from "../../Components/card/Card";
import Loading from "../../Components/Loading/Loading";


const Dashboard = () => {
  let palanName = localStorage.getItem("planName");
  let websiteCount = localStorage.getItem("countWebsitePlan");
  let websiteUser = localStorage.getItem("websiteUser");
  let proxyAll = localStorage.getItem("proxyAll");


  const commaNumber = require('comma-number');

  const result1 = commaNumber(proxyAll)

  return (
    <>
      <Wrapper>
        {/* <Loading className="w-5 h-5" /> */}

        <div className="flex flex-col justify-center items-center">
          <div className="w-full flex md:flex-row flex-col  justify-center items-center bg-violet-200 rounded-lg ">
          <img src={nn} alt="pic" className=" md:hidden w-36 rounded-lg h-fit " />

            <p className="md:p-3 p-5  text-gray-500 text-justify ">
              کاربر گرامی به پوشانه خوش آمدید میتوانید برای ارتقای حساب کاربری
              خود از طریق <a className="underline text-violet-500" href="https://api.whatsapp.com/send?phone=989124249313&text=سلام وقت بخیر ">پشتیبانی</a> اقدام کنید
            </p>
            <img src={nn} alt="pic" className="hidden md:block w-36 rounded-lg h-fit " />
          </div>
          <div className="flex justify-center w-full items-center lg:flex lg:justify-center lg:items-center">
           
          <div
            className="grid grid-cols-1 md:grid-cols-2 w-full mt-5 "
            style={{ direction: "ltr" }}
          >
           <Card className="flex flex-col justify-center items-center">
              {" "}
              <h1 className="tracking-wide font-semibold text-slate-600">
              تعداد کل پروکسی ها {" "}
              </h1>
              <span className="mt-5 text-violet-500 font-bold text-2xl"> {result1} </span>
            </Card>
            <Card className="flex flex-col justify-center items-center p-3">
              <h2 className="tracking-wide font-semibold text-slate-600">
                مشخصات پلن فعال 
              </h2>
              <span
                className="mt-5 text-violet-500 text-justify"
                style={{ direction: "rtl" }}
              >
                <span className=" text-slate-700">نام</span> : <span className="font-bold text-xl">{palanName}</span>{" "}
                <br />
                <span className=" text-slate-700">
                  {" "}
                تعداد وب سایت ها / مجاز :
                </span>{" "}
                 <span className="font-bold text-xl">{websiteUser} / {websiteCount} </span>
              </span>
            </Card>
           </div>
           
           
          </div>
         
        </div>
      </Wrapper>
    </>
  );
};

export default Dashboard;
