import React, { useState } from "react";
import SideBar from "../Sidebar/SideBar";
import NavbarDash from "../../Pages/Dashboard/NavbarDashboard/NavbarDash";

const Wrapper = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex w-12/12 relative" style={{ width: "100vw" }}>
      <div>
        <SideBar open={open} setOpen={setOpen} />
      </div>

      <div className="flex flex-col w-full min-h-screen bg-slate-800">
        <NavbarDash />
        <div
          className={`${
            open ? "mr-56" : "mr-20"
          } bg-violet-100 mx-7 my-3 h-full w-12/12 flex rounded-xl   flex-col  py-4 px-6 duration-300 relative`}
        >
          {children} 
          
        
        </div>   <div className="w-full h-12 text-gray-300 font-medium  bg-slate-800 flex justify-center items-center text-xs">
            Version 1.6.3
          </div>

        
      </div>
    </div>
  );
};

export default Wrapper;
