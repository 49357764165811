import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Breadcrumb/BreadCrumb";
import Button from "../../Components/Button/Button";
import Wrapper from "../../Components/HOC/Wrapper";
import List from "../../Components/List/List";
import Modal from "../../Components/Moudal/Modal";
import ModalAddWebsite from "../../Components/Moudal/ModalAddWebsite";
import { useWebsiteList } from "../../hook/useWebsite";
import { useProxyWebsite } from "../../hook/useProxyWebsite";
import { useCreateWebsite } from "../../hook/useWebsite";
import { useDeleteWebsite } from "../../hook/useWebsite";
import { useUpdateWebsite } from "../../hook/useWebsite";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const Website = () => {
  const [wesiteInput, setWebsiteInput] = useState({
    pWebsiteId: "",
    websiteUrl: "",
    judgeUrl: "",
    proxyCount: "",
  });

  const { data: WebsiteList, isLoading: webSiteLoading } = useWebsiteList();

  useEffect(() => {
    console.log(WebsiteList);
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const [modalAddWebsite, setModalAddWebsite] = useState(false);

  const columns = [
    { name: "", path: "websiteUrl" },
    // { name: "", path: "proxyCount" },
  ];

  const { mutate: createWebsite } = useCreateWebsite();
  const { mutate: deleteWebsite } = useDeleteWebsite();
  const { mutate: updateWebsite } = useUpdateWebsite();

  // const [proxyListState, setProxyListState] = useState(
  //   proxyListItmes?.map((item) => item)[0]
  // );
  useEffect(() => {
    // console.log(proxyListItmes[0]["pProxies"][0]);
    // console.table(proxyList)
  }, []);

  const [editbtn, setEditBtn] = useState(false);

  const editWebsite = (rowData) => {
    setEditBtn(true);
    setWebsiteInput({
      pWebsiteId: rowData.pWebsiteId,
      websiteUrl: rowData.websiteUrl,
      judgeUrl: rowData.judgeUrl,
    });
    console.log("editbtn", wesiteInput);
    setModalAddWebsite(true);
    console.log(rowData);
  };

  const updateHandler = () => {
    if (wesiteInput.judgeUrl === "") {
      toast.error(" Judge Urlنمی تواند خالی باشد ");
    } else {
      updateWebsite(wesiteInput);
      setModalAddWebsite(false);
    }
  };

  const removeWebsite = (rowData) => {
    Swal.fire({
      title: "آیا از حذف وبسایت مطمئن هستید؟",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "انصراف",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "حذف",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWebsite(rowData.pWebsiteId);
      }
    });
  };

  const addWebsite = () => {
    let data = { ...wesiteInput };
    if (wesiteInput.websiteUrl === "") {
      toast.error("website url نمی تواند خالی باشد");
    } else if (wesiteInput.judgeUrl === "") {
      toast.error("Judge Url نمی تواند خالی باشد");
    } else {
      Swal.fire({
        title: "آیا از آدرس url وارد شده مطمئن هستید؟",
        text: "در صورت تایید url وارد شده غیر قابل تغییر خواهد بود ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "بله ، ادامه",
        cancelButtonText: " انصراف",
      }).then((result) => {
        if (result.isConfirmed) {}
          createWebsite(data);
          setModalAddWebsite(false);
        
      });
    }
  };

  const onChnageHandler = (e) => {
    setWebsiteInput({
      ...wesiteInput,
      [e.target.name]: e.target.value,
    });
  };

  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const [showMoreInoooo, setShowMoreInoooo] = React.useState(false);

  const commaNumber = require("comma-number");

  const result1 = commaNumber(WebsiteList?.map((item) => item.proxyCount));

  return (
    <div>
      <Wrapper>
        <button
          className=" bg-violet-500 text-zinc-100 rounded-md  text-sm mb-5 py-2 px-4 w-fit"
          onClick={() => {
            setWebsiteInput({
              pWebsiteId: "",
              websiteUrl: "",
              judgeUrl: "",
            });
            setModalAddWebsite(true);
          }}
        >
          <p className=""> اضافه کردن وبسایت</p>
        </button>
        <ModalAddWebsite
          editbtn={editbtn}
          valueUrl={wesiteInput.websiteUrl}
          valueJudge={wesiteInput.judgeUrl}
          modalAddWebsite={modalAddWebsite}
          setModalAddWebsite={setModalAddWebsite}
          onChange={onChnageHandler}
          onSubmit={wesiteInput.pWebsiteId === "" ? addWebsite : updateHandler}
          onMouseEnter={() => setShowMoreInfo(true)}
          onMouseLeave={() => setShowMoreInfo(false)}
          showMoreInfo={showMoreInfo}
          showMoreInoooo={showMoreInoooo}
          readOnly={wesiteInput.pWebsiteId === "" ? false : true}
          content={wesiteInput.pWebsiteId === "" ? true : false}
        />

        <Modal
          // data={proxyListItmes[0]['pProxies'][0]}
          // data={data}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
        <List
          Loading={webSiteLoading}
          data={WebsiteList}
          // urlInput={WebsiteList?.map(item =>item?.websiteUrl)[0]}
          columns={columns}
          noBtn={true}
          editBtn={editWebsite}
          deletebtn={removeWebsite}
          className="bg-none border-none"
          DisplyProxyLog={WebsiteList}
          lastUpdate={WebsiteList?.map((item) => item.lastUpdate)}
        ></List>
      </Wrapper>
    </div>
  );
};

export default Website;
