import axios from "axios";
import { BaseUrl } from "../utils/constant";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import errormsg from "../assets/icon/error.png";

const client = axios.create({
  baseURL: BaseUrl,
});
client.interceptors.request.use(async (config) => {
  let _authToken = "";
  if (localStorage.getItem("_authToken")) {
    _authToken = "Bearer " + localStorage.getItem("_authToken");
  } else if (!localStorage.getItem("_authToken")){
    _authToken = ""
  }
  config.headers.Authorization = _authToken;
  return config;
});
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>{" "} <div className="flex-shrink-0 pt-0.5">
                  <img className="h-10 w-10 " src={errormsg} alt="" />
                </div>
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="justify-center items-center flex ml-3 pl-5 ">
                  <p className="text-sm font-medium text-gray-900">
                  401 error!
                  </p>
                 
                </div>{" "}
               
              </div>
            </div>
          </div>
        ));
      }
    }
    return Promise.reject(error);
  }
);
export default client;
