import React, { useEffect, useState } from "react";
import { useProxyLogList } from "../hook/useProxyLog";

export default function Pagination({
  Showpost,
  totalShowPost,
  totalData,
  Previous,
  next,
  currentPage,
  btnpagination,
  pageCount
}) {

  return (
    <div className="py-2 flex flex-col justify-center items-center ">
      <>
        {/* <nav className="block"></nav> */}
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            {/* <a
              onClick={() => {
                Previous();
              }}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span>Previous</span>
            </a> */}
            <nav
              aria-label="Page navigation example"
              style={{ direction: "ltr" }}
            >
              <ul className="inline-flex items-center -space-x-px">
                <li>
                  <a className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <span className="sr-only">Previous</span>
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
                {Array(pageCount)?.map((_, index) => {
                  console.log("nlkn" + pageCount);
                  return (
                    <li key={index}>
                      <button
                        className="z-10 py-2 px-3 leading-tight text-blue-600 bg-blue-50 border border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                        onClick={() => btnpagination(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  );
                })}

                {/* <li>
                  <button
                    onClick={() => btnpagination(2)}
                    className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    2
                  </button>
                </li>
                <li>
                  <button
                    className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"

                    onClick={() => btnpagination(3)}
                    aria-current="page"
                  >
                    3
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => btnpagination(4)}
                    className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    4
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => btnpagination(5)}
                    className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    5
                  </button>
                </li> */}
                <li>
                  <button className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <span className="sr-only">Next</span>
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>

            {/* <button
              onClick={() => {
                next();
              }}
              classNameName="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span>Next</span>
            </button> */}
          </nav>
        </div>
        {/* <div>
          <p className="text-sm text-gray-700">
            نمایش
            <span className="font-medium">{Showpost}</span>
            از
            <span className="font-medium"> {totalData} </span>
            نتیجه
          </p>
        </div> */}
      </>
    </div>
  );
}
