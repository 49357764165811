import * as api from "../apis/DrCaptcha";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "..";
import Swal from "sweetalert2";

const useCapatcha = () => { 
    return useMutation(api.checkCaptcha, {
        onSuccess: (response, values) => {
          if (response.status) {
            queryClient.setQueryData("captchValue", values);
            // navigate("/login");
          } else {
            Swal.fire({
              icon: "error",
              title: "خطا",
              text: response.message,
            });
          }
        },
      })
};

export { useCapatcha };
