import React from "react";

const Input = ({ className, placeholder, name, value,type, onChange, readOnly, error }) => {
  return (
    <>
   
      <input
        className={` bg-transparent mt-3 rounded-3xl border-2  mx-auto border-violet-600 py-3 bg-none text-zinc-100 ${error ? "border border-red-700" : ""} ${className}`}
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
          onChange={onChange}
          readOnly={readOnly}
      />
      {error ?  <p>{error}</p> : ""}
    </>
  );
};

export default Input;
