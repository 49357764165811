import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import bg from "../../src/assets/icon/02.png";
import img from "../../src/assets/icon/img.png";
import rout from "../../src/assets/icon/01.png";
import { Check, CheckCircle, Telephone, Tiktok, Whatsapp } from "react-bootstrap-icons";
import Footer from "../Components/Footer/Footer";
import PricingTable from "../Components/pricing table/PricingTable";
import { Link } from "react-router-dom";
import imgg from "../assets/icon/section-demo.png";

const Index = () => {
  return (
    <>
      <section id="index"></section>
      <div className="flex justify-center items-center flex-col w-full">
        <Navbar />
        <div className="grid grid-cols-1 md:grid-cols-2 h-1/2  md:h-fit w-full bg-slate-800 ">
          <div className="flex  justify-end items-end lg:justify-center lg:items-center ml-16">
            <img
              src={bg}
              alt="pic"
              className="hidden md:block w-96 h-fit pr-8 left-0 pt-5"
            ></img>
          </div>
          <div className="flex flex-col items-start justify-start  mx-9 text-zinc-100">
            <div className=" md:mt-20 mb-12 text-xl   ">
              <span className=" justify-start  font-semibold">
                {/* <h1> */}

                <p className="text-md pt-12 pl-5 leading-loose md:pt-5 font-bold mr-0">
                  اولین سرویس دهنده اسکرپینگ و کراولینگ در ایران بدون محدودیت
                  کراول کنید!
                </p>
                <p className="text-xs font-thin mt-12">
                  مدیریت و پایش همه کروال ها از یک پنل واحد
                </p>
                <p className="text-xs font-thin mt-3">
                  گزارش گیری لحظه ای و جامع
                </p>
              </span>
              {/* <Link to="login">
                <button className="font-thin text-base rounded-sm py-2 mt-10 px-8 bg-violet-500">
                  شروع کنید
                </button>
              </Link> */}
            </div>
            {/* ////////////////////////////////////////////////////////////////////////////// */}
          </div>
        </div>
        <svg
          className="hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#1E293B"
            fill-opacity="1"
            d="M0,0L1440,160L1440,0L0,0Z"
          ></path>
        </svg>
        <div className="grid grid-cols-1 gap-12 md:gap-16 md:grid md:grid-cols-2  md:mt-none   justify-center">
          <div className="flex flex-col justify-center">
            <section id="demo"></section>
            <h1 className="md:px-14 mr-5 md:mr-0 p-3 mt-10 md:mt-0 md:p-0 md:text-3xl text-xl md:mb-4 text-gray-700 justify-start tracking-wider font-bold py-5">
              دمو پوشانه
            </h1>
            <div className="md:block flex justify-center items-center">
              {/* <img alt="pic" src={rout} className="md:hidden w-fit px-7" /> */}
              <img
                src={bg}
                alt="pic"
                className=" md:hidden w-fit p-7 h-fit pr-8 left-0 pt-5"
              ></img>
            </div>
            <div className="  mx-5 md:border-none md:shadow-none md:mx-0 ">
              <p className="text-sm md:text-base md:text-xl p-5 md:p-0 md:px-14 text-gray-600">
                براي درخواست دمو و همچنين مشاوره در خصوص چگونگي سرويس، مي توانيد
                با پشتيبان وبسايت مذاکره کنيد. لازم به توضيح است به دليل خاص
                بودن نوع وبسايت، از دمو به افراد مستقل معذوريم و صرفا اين دمو
                براي شرکت ها ميسر مي باشد
              </p>
            </div>

            <div className="flex flex-row justify-center items-center  md:px-14 mt-5 md:mt-14">
              <a
                href="https://api.whatsapp.com/send?phone=989124249313&text= سلام وقت بخیر در خصوص درخواست دمو مرا راهنمایی کنید"
                className="flex flex-col md:flex md:flex-row"
              >
                <button className="flex px-4 rounded-md right-0 m-3 bg-slate-800 ">
                  <Whatsapp className="mx-2 w-4 mt-3 text-white" />
                  <span className="p-3 text-white  text-sm md:block">
                    Whatsapp
                  </span>
                </button>
                <button className="flex px-4 rounded-md right-0 m-3  bg-slate-800">
                  <Telephone className="mx-2 w-4 mt-3 text-white" />
                  <span className="p-3 md:block text-sm  text-white">Call</span>
                </button>
              </a>
            </div>
          </div>

          <div className="hidden md:block flex justify-center items-center">
            <img alt="pic" src={rout} className="w-fit" />
          </div>
        </div>

        {/* <div className="w-5/6 h-72 bg-violet-200 mt-52 rounded-lg"></div> */}
        <div className="grid grid-cols-1 md:grid md:grid-cols-2 md:gap-16 mt-28 md:mt-52">
          {/* <div className="md:hidden items-center justify-center flex px-20  text-xl w-full text-justify">
           <p>
           پوشانه سرویسی است که به شما کمک می کند بدون محدودیت و نگرانی از بلاک
            شدن IP هر وبسایت یا URL مورد نظر خودتان را کراول یا اسکرپینگ کنید.
           </p>
            <ul >
            <li>تالرترتار</li>
            <li>تالرترتار</li>
            <li>تالرترتار</li>

          </ul>
          </div> */}

          <div className="flex justify-center items-center">
            <img alt="" src={img} className="w-full p-14 md:w-fit md:p-0" />
          </div>

          <div className=" p-5 flex px-20  text-2xl  text-justify">
            <span className=" md:block ">
              <div className="flex flex-row">
                <p className="h-fit">
                  {" "}
                  <p className="mx-2 border-b-4 w-16 border-gray-700 font-bold text-gray-700 p-0 m-0 leading-loose">
                    {" "}
                    پوشانه
                  </p>{" "}
                </p>
                <p className="leading-loose md:text-lg mt-2 text-sm text-gray-700  ">
                  {" "}
                  سرویسی است که به شما کمک می کند بدون محدودیت و نگرانی از بلاک
                  شدن IP هر وبسایت یا URL مورد نظر خودتان را کراول یا اسکرپینگ
                  کنید.
                </p>
              </div>
              <ul className="mr-0  md:mr-14 text-justify mt-7">
                <li className="text-gray-500 text-sm mt-3 flex">
                  {" "}
                  <CheckCircle className="mx-2 w-10 text-green-600" /> امکان ارسال تا
                  ١٠ میلیون درخواست مختلف
                </li>
                <li className="text-gray-500 text-sm mt-3 flex">
                  {" "}
                  <CheckCircle className="mx-2 w-10 text-green-600" /> قابلیت پردازش
                  دستورات جاوا اسکریپت
                </li>
                <li className="text-gray-500 text-sm mt-3 flex">
                  {" "}
                  <CheckCircle className="mx-2 w-10 text-green-600" /> امکان تهیه
                  اسکرین شات از صفحات کراول شده
                </li>
                <li className="text-gray-500 text-sm mt-3 flex">
                  {" "}
                  <CheckCircle className="mx-2 w-10 text-green-600" /> قابلیت کراول
                  صفحات به صورت ناشناس
                </li>
                <li className="text-gray-500 text-sm mt-3 flex">
                  {" "}
                  <CheckCircle className="mx-1 w-10  text-green-600" />عبور از فایروال
                  CloudFlare
                </li>
              </ul>
            </span>
          </div>
        </div>
        <section id="pricingTable">
          <PricingTable />
        </section>
      </div>{" "}
      <Footer />
    </>
  );
};

export default Index;
