import React, { useState } from "react";
import Wrapper from "../../Components/HOC/Wrapper";
import lock from "../../assets/icon/padlock (1).png";
import Input from "../../Components/Input/Input";

const Setting = () => {

  const [formValue , setFormValue ] = useState({
    password: "",
    confirmpassword: ""
  });


  return (
    <div>
      <Wrapper>
        <div className="flex justify-center items-center py-8">
          <div className="flex flex-col justify-center items-center w-full h-screen bg-gray-50 py-6 rounded-lg">
            <div className="rounded-full w-40 h-40 bg-violet-500 py-4 px-4">
              <img src={lock} alt="pic" className="w-fit h-fit" />
            </div>
            <Input placeholder="رمز عبور جدید" name="password" type="password" value={formValue.password} onChange="" className="w-40 md:w-96 mt-5 h-12 placeholder:text-sm placeholder:p-5" />
            <Input placeholder="تکرار رمز عبور" name="confirmpassword" type="confirmpassword" value={formValue.confirmpassword} onChange="" className="w-40 md:w-96 mt-2 h-12 placeholder:text-sm placeholder:p-5" />
           
            <button
              onClick={""}
              type="submit"
              className="bg-violet-500 text-gray-100 h-12 w-40 rounded-3xl  mt-8"
            >
              ذخیره
            </button>
          </div>
        </div>
      </Wrapper>
    
    </div>
  );
};

export default Setting;
