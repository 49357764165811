import React, { useEffect, useState } from "react";
import Wrapper from "../../Components/HOC/Wrapper";
import List from "../../Components/List/List";
import { useProxyLogList } from "../../hook/useProxyLog";

const ProxyLoglist = () => {
  const columns = [
    { name: "ID", path: "pProxyId" },
    { name: "IP", path: "ip" },
    { name: "PORT", path: "port" },
    { name: "COUNTRY", path: "country" },
  ];

  const { data: ProxyLogList } = useProxyLogList({
    page: 1,
    itemcount: 20,
    orderBy: "",
    filter: "",
  });

const [proxyList , setProxyList] = useState(ProxyLogList?.map(item => item.pProxy))



  useEffect(() => {
    // console.table(ProxyLogList?.map(item => item.pProxy));
    // setProxyList(JSON.stringify(ProxyLogList?.map(item => item.pProxy)))
    // setTimeout(() => {
    //   console.log(proxyList)
    // }, 3000);
  }, []);

  return (
    <Wrapper>
      {/* <List data={proxyList} columns={columns} noBtn={false} /> */}
    </Wrapper>
  );
};

export default ProxyLoglist;
