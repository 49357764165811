import React from "react";

const RequestBuilderInput = ({ name, type, value, onChange, className, placeholder,children }) => {
  return (
    <>
     
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        className={`${className} px-6 py-2  rounded-md border border-slate-300 shadow-sm`}
        placeholder={placeholder}
      />
    </>
  );
};

export default RequestBuilderInput;
